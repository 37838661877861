@import "../../App.scss";

.mentor-connect-module {
    font-family: Roboto;
    h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: $heading;
    }
    p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
    }
    .qna-btns {
        margin-top: 10px;
        font-family: Roboto;
        width: 88%;
        h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }
        button {
            background: #f7f7fa;
            border: 1px solid #dddde2;
            color: #484750;
            font-weight: noraml;
            font-size: 14px;
            line-height: 18px;
            // border: none;
            padding: 7px 31px;
            border-radius: 4px;
        }
        .active-btn {
            background: $chart-color;
            border: none;
            color: #fff;
            font-weight: bold;
        }
        .new-btn {
            background: $button-color;
            border: none;
            color: #fff;
            font-weight: noraml;
            font-size: 14px;
            line-height: 18px;
            // border: none;
            padding: 9px 31px;
            border-radius: 4px;

            img {
                vertical-align: text-top;
            }
        }
    }
    .qna-search {
        margin-top: 10px;
        background-color: #fff;
        width: 88.5%;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding: 25px 22px;
        // padding-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .faculty-search {
            position: relative;
            display: inline-block;
            width: 55%;
            .form-control-feedback {
                position: absolute;
                display: block;
                width: 2.375rem;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                margin-top: 3px;
                width: 24px;
                margin-left: 5px;
            }

            .form-control {
                background: #f7f7fa;
                padding-left: 1.9rem;
                border: none;
            }
        }
        .filters {
            width: 45%;

            .css-2b097c-container .css-1okebmr-indicatorSeparator {
                display: none !important;
            }
        }
    }
    .faculty-cards {
        width: 89.5%;
        .card-01 .card-body {
            position: relative;
            padding-top: 0px;
            padding-bottom: 10px;
        }

        .card-01 .height-fix {
            height: 455px;
            overflow: hidden;
        }
        .card {
            border: none;
            .elipsis {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .elipsis1 {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }

        .profile-box {
            background-size: cover;
            float: left;
            width: 170px;
            height: 110px;
            display: flex;
            justify-content: center;
            text-align: center;
            padding: 15px 0;
            position: relative;
            overflow: hidden;
            .intial {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: $dark-background;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }
        .card-01 .height-fix .card-img-top {
            width: auto !important;
        }
        .card-img,
        .card-img-bottom,
        .card-img-top {
            height: 100% !important;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 45% !important;
        }
    }
    .table-admin {
        // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        background: #fff;
        // margin-top: 40px;
        width: 88.5%;
        .table {
            margin-bottom: 0px;
            thead {
                hr {
                    margin-bottom: -1rem;
                    border-top: none;
                    margin-right: 0px;
                    margin-top: 0px;
                }
                tr {
                    th {
                        // box-shadow: 4px 0 6px -4px rgba(0, 0, 0, 0.25);
                        border-bottom: 2px solid $chart-color;
                        border-top: none;
                        color: $chart-color;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        text-align: left;
                    }
                }
            }

            tbody {
                tr {
                    .empty-list {
                        background: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        padding: 40px;
                        margin-top: 8px;
                        width: 100%;
                        // position: absolute;
                        img {
                            width: 350px;
                            height: 210px;
                        }
                        p {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            margin-top: 15px;
                            color: #696873;
                        }
                    }
                    td {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        vertical-align: middle;
                        border-top: 8px solid #f6f6f6 !important;
                        text-align: left;
                        .intial {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            background-color: $dark-background;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #fff;
                            img {
                                width: 30px;
                                height: 30px;
                            }
                        }
                        p {
                            color: #696873;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 18px;
                            span {
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: #9b9aa3;
                            }
                        }
                        .arrow-rotate {
                            width: 16px;
                            height: 16px;
                            transform: rotate(90deg);
                            cursor: pointer;
                        }
                    }
                    .action {
                        cursor: pointer;
                        img {
                            display: inline-block;
                            vertical-align: middle;
                        }
                        button {
                            border: none;
                            background: none;
                            //    &:hover{
                            //        background: #DDDDE2;
                            //        width: 30px;
                            //        height: 30px;
                            //        border-radius: 50%;

                            //    }
                        }
                    }
                }
            }
        }
    }
    .user-pagination {
        display: flex;
        align-items: center;
        margin-top: 25px;
        img {
            vertical-align: middle;
            cursor: pointer;
        }
        div {
            display: flex;
            align-items: center;
            width: 110px;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            input {
                width: 30%;
                border-radius: 4px;
                border: 1px solid #9b9aa3;
                padding: 0.25rem 0.3rem;
                text-align: center;
            }
        }
    }
}

.add-query-form {
    width: 86%;
    margin-left: 5px;
    background-color: #fff;
    .query-form {
        padding: 25px 45px;
        .query-header {
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            width: 55%;
            -webkit-align-items: center;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #000000;
            }
        }
        .mandatory {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
            color: #9b9aa3;
            margin-top: 10px;
        }
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        .css-2b097c-container .css-1okebmr-indicatorSeparator {
            display: none !important;
        }
        .css-b62m3t-container {
            .css-13cymwt-control {
                border: 1px solid $button-color !important;
            }
        }
        .faculty-option {
            .intial {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: $dark-background;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 14px;
            }
        }
        textarea {
            border: 1px solid $button-color;
        }
        .resource-holder {
            list-style: none;
            margin-top: 10px;
            width: 90%;
            .resource {
                display: flex;
                min-height: 35px;
                width: 100%;
                padding: 8px 4px;
                position: relative;
                .edit-bin {
                    cursor: pointer;
                }

                .recent-title {
                    margin-left: -30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h1 {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        font-family: Roboto;
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                    }
                }
            }
        }
        .form-group {
            small {
                color: red;
                font-size: 14px;
            }
        }
        .submit-btn {
            border: none;
            background-color: $button-color;
            color: #fff;
            border-radius: 4px;
            padding: 11px 50px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
        }
    }
}

.query-details-form {
    width: 86%;
    margin-left: 5px;
    background-color: #fff;
    padding: 25px 35px;
    .query-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 55%;
        -webkit-align-items: center;
        align-items: center;
        .mark-as {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            cursor: pointer;
        }

        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: #000000;
        }
    }
    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
    }
    p {
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #696873;
        margin-top: 5px;
    }
    .query-details {
        margin-top: 30px;
    }
    .conversations-details {
        margin-top: 15px;
        width: 89.5%;
        hr {
            margin-top: 10px;
            margin-right: 20px;
            margin-bottom: 10px;
        }
        h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            color: #696873;
            text-align: justify;
        }
        .faculty-response {
            margin-top: 30px;
            .intial {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: $dark-background;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
            }
        }
    }
    .reply-btn {
        margin-top: 40px;
        width: 90%;
        textarea {
            border: 1px solid $chart-color;
        }
        .resource-holder {
            list-style: none;
            margin-top: 10px;
            width: 90%;
            .resource {
                display: flex;
                min-height: 35px;
                width: 100%;
                padding: 8px 4px;
                position: relative;
                .edit-bin {
                    cursor: pointer;
                }

                .recent-title {
                    margin-left: -30px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h1 {
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                        font-family: Roboto;
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                    }
                    .elipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        button {
            margin-top: 12px;
            border: none;
            border-radius: 4px;
            background-color: $button-color;
            color: #fff;
            padding: 10px 51px;
            position: relative;
            width: 140px;
            height: 40px;
        }
    }
}

.confirm-mark {
    width: 400px;
    .del-modal {
        p {
            text-align: left;
            font: normal normal normal 16px/24px Roboto;
            letter-spacing: 0px;
            color: #9c9c9c;
            opacity: 1;
        }
        h2 {
            text-align: left;
            font: normal normal 600 18px/24px Roboto;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
        }
        .del-btn {
            color: $button-color;
            background: #ffffff;
            border: none;
            padding: 10px;
            cursor: pointer;
        }
    }
}
@media (max-width: 767px) {
    .confirm-mark {
        width: unset;
    }
    .mentor-connect-module {
        padding: 10px;
        margin-bottom: 30px;
        position: relative;
        .qna-btns {
            width: 100%;
            .btn-mobile {
                position: absolute;
                right: 15px;
                top: 22px;
            }
        }
        .qna-search {
            width: unset;
            margin: 5px -5px 10px;
            .faculty-search {
                width: 96%;
            }
            .filters {
                width: 100%;
                margin-top: 15px;
                .css-2b097c-container {
                    z-index: 5 !important;
                }
            }
        }
        .faculty-cards {
            width: 100%;
            .card {
                min-width: 165px !important;
            }
        }
        .table-admin {
            width: auto;
            margin-left: -15px;
            position: relative;
            overflow: auto;
            white-space: nowrap;
            table {
                text-align: center;
            }

            table tr th:first-child {
                z-index: 4 !important;
            }

            table th,
            table td {
                // white-space: nowrap;
                padding: 10px 20px;
            }
            table tr th:first-child,
            table tr td:first-child {
                background: #fff;
                left: -1px;
                min-width: 250px;
                overflow: hidden;
                position: sticky;
                position: -webkit-sticky;
                z-index: 4;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
            }

            table tr th {
                position: sticky;
                top: 0;
                z-index: 3;
                background: #fff;
            }
        }
    }

    .add-query-form {
        width: unset;
        margin-left: -15px;
        margin-top: -20px;
        margin-bottom: 10px;
        .query-form {
            padding: 25px 30px;
            .resource-holder {
                width: 100%;
                .resource {
                    .recent-title {
                        margin-left: 0px;
                        .elipsis {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
    }

    .query-details-form {
        width: unset;
        background-color: #f7f7fa;
        padding: 25px 10px;
        margin-top: -20px;
        .query-header {
            p {
                margin-top: 0px;
            }
        }
        .conversations-details {
            margin-top: 30px;
        }
        .query-details {
            .reply-btn {
                .resource-holder {
                    width: 100%;
                    .resource {
                        .recent-title {
                            margin-left: 0px;
                            .elipsis {
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
    }

    .add-topic-modal {
        width: unset;
    }
}

@media (min-width: 414px) and (max-width: 736px) {
    .qna-module .faculty-cards .card {
        min-width: 185px !important;
    }
}

@media (min-width: 1600px) {
    tr {
        .empty-list {
            width: 72.5% !important;
        }
    }
}
