@import "../../App.scss";

.user-module {
    background-color: #fff;
    width: 92%;
    margin-top: 20px;

    .add-btn {
        background-color: #8b7df2;
        color: #fff;
        border: none;
        border-radius: 4px;
        height: 38px;
        width: 156px;
    }
    .engaged-users {
        background-color: #fff;
        .users-heading {
            overflow: hidden;
            padding: 22px 34px 15px;
            h3 {
                font-size: 20px;
                font-family: "Roboto";
                font-weight: 600;
                float: left;
            }
        }
        .history-header1 {
            border-top: rgba(204, 204, 204, 0.48) solid 1px;
            top: 66px;
            margin-bottom: 0;
            background-color: #fff;
            position: sticky;
            position: -webkit-sticky;
            overflow: visible;
            left: 0;
            width: 100%;
            z-index: 1;
            padding: 0 30px 0 15px;
            ul {
                display: flex;
                list-style: none;
                overflow: auto;
                white-space: nowrap;
                padding: 0 0 0 5px;
                width: 100%;
                list-style-type: none;
                text-align: center;
                margin-top: 8px;
                font-family: "Roboto";
                li {
                    padding: 10px 0;
                    margin-right: 35px;
                    cursor: pointer;
                    width: 20%;
                    font-size: 18px;
                    text-align: center;
                    display: block;
                    color: #212529;
                    font-weight: normal;
                }
                .active-Tab {
                    border-bottom: 4px solid $button-color;
                    color: $button-color !important;
                    font-weight: 600;
                }
            }
        }
        .table-admin {
            padding: 0 23px;
            .table-body {
                tr {
                    td {
                        color: #4d4f5c;
                        font-family: "Roboto";
                        padding: 0.75rem;
                        vertical-align: middle;
                        border-top: 1px solid #dee2e6;
                    }
                }
            }

            .header-table {
                background-color: #f5f6fa;
                th {
                    color: #a3a6b4;
                    font-size: 14px;
                    font-family: "Roboto";
                    border-bottom: none !important;
                    border-top: none !important;
                    padding: 15px 13px;
                    font-family: "Roboto";
                }
            }
        }
    }

    .search-company {
        .url-input {
            position: relative;
        }
        .err {
            color: red;
            text-align: left !important;
        }
        .company-suggestion {
            position: absolute;
            z-index: 21;
            width: 100%;
            // left: 12%;
            background: #fff;
            text-align: left;
            border-radius: 4px;
            box-shadow: 0px 5px 7px 2px #0000001f;

            i {
                margin-right: 7px;
            }
            hr {
                margin-top: -10px;
            }
            ul {
                max-height: 170px;
                overflow: auto;
                &:hover li:hover {
                    background: #ddd;
                }
                li {
                    display: block;
                    cursor: pointer;
                    width: 100%;
                    margin-left: -5px;
                    padding: 7px 0 6px 0px;
                    border-bottom: 1px solid #00000026;
                    margin: 0;
                    font-size: 16px;
                }
            }
        }
    }

    #company-suggest {
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: white;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            max-height: 10px;
            background-color: #e9e8e8;
        }
    }

    #company-suggest {
        scrollbar-width: thin;
    }
}
.add-reward-model {
    width: 35%;
    font-family: "Roboto";
    .model-header {
        position: relative;
        padding: 0.7rem 0.7rem;
        text-align: center;
        display: block;
        border-bottom: 1px solid rgb(0, 0, 0, 0.2);
        h5 {
            // color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 15px;
            cursor: pointer;
        }
    }
    .css-13cymwt-control {
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
    .css-t3ipsp-control {
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
    label {
        font-weight: bold;
    }
    .err {
        color: red;
    }
    .program-image {
        width: 139px;
        height: 78px;
        background-color: #f7f7fa;
        margin-top: 10px;
        margin-right: 15px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    small {
        font-size: 12px;
        color: red;
    }
    span {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #8b7df2;
        margin-bottom: 10px;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            cursor: pointer;
        }
        .file-upload {
            position: absolute;
            left: 3px;
            height: 30px;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            top: -3px;
        }
    }
    .add-btn {
        background-color: #8b7df2;
        border: none;
        color: white;
        padding: 4px;
        border-radius: 4px;
        width: 156px;
        text-align: center;
        font: normal normal 600 15px/27px Roboto;
        letter-spacing: 0px;
        color: #ffffff;
    }
}

.add-contest-modal {
    width: 90%;
    background-color: #fff;
    padding: 30px;
    font-family: "Roboto";
    .header {
        text-align: center;
        position: relative;
        display: flex;
        justify-content: flex-start;
        border-bottom: none;
        margin-bottom: 15px;
        img {
            cursor: pointer;
        }
        h5 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 20px;
            color: #242326;
        }
    }

    table {
        height: 300px; // <-- Select the height of the table
        display: block;
        tr {
            width: 100%;
            display: inline-table;
            table-layout: fixed;
        }
        tbody {
            overflow-y: auto;
            height: 200px; //  <-- Select the height of the body
            // width: 100%;
            position: absolute;
            width: 29%;
        }
    }

    .add-contest {
        width: 40%;

        label {
            font-weight: bold;
        }
        .err {
            color: red;
        }
        .tableFixHead {
            .table {
                thead th {
                    vertical-align: middle;
                    border-top: 2px solid #dee2e6;
                    border-bottom: 2px solid #dee2e6;
                }
            }
        }
        small {
            font-size: 12px;
            color: red;
        }
        span {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #8b7df2;
            margin-bottom: 10px;
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
                cursor: pointer;
            }
            .file-upload {
                position: absolute;
                left: 3px;
                height: 30px;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                top: -3px;
            }
        }

        .add-btn {
            background-color: #8b7df2;
            border: none;
            color: white;
            padding: 4px;
            border-radius: 4px;
            width: 156px;
            text-align: center;
            font: normal normal 600 15px/27px Roboto;
            letter-spacing: 0px;
            color: #ffffff;
        }
    }
}

.confirm-modal {
    width: 472px;
    padding: 20px;
    h1 {
        // text-align: left;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;

        //   color: $heading-color;
        a {
            text-decoration: underline !important;
        }
    }

    p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #535259;
        margin-top: 10px;
    }
    .save-btn {
        margin-top: 10px;
        font-family: Roboto;
        button {
            border: none;
            background-color: #8b7df2 !important;
            width: 144px;
            height: 40px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
            border-radius: 4px;
            &:nth-child(2) {
                background-color: #fff !important;
                border: 1px solid #9d99bf;
                color: #222222;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.add-reward {
    .content-drawer {
        padding: 0px;
        // overflow: auto;
        // // max-height: 400px;
        // &::-webkit-scrollbar {
        //     display: none;
        // }
        #scrollableDiv {
            & ::-webkit-scrollbar-track {
                border-radius: 0px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 8px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0px;
                max-height: 0px;
                background-color: #e9e8e8;
            }
            scrollbar-width: thin;
        }

        .content-list {
            list-style: none;
            // padding: 22px 30px;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0px;
                border-bottom: 1px solid #dddde2;
                padding-left: 28px;
                padding-right: 30px;
                .subject-name {
                    article {
                        img {
                            width: 70px;
                            height: 40px;
                        }
                    }

                    h3 {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #9b9aa3;
                        img {
                            width: 16px;
                            height: 16px;
                            vertical-align: middle;
                        }
                    }
                }
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #696873;
                }
            }
        }
    }
}

///
.checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation:
        fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 7;
    stroke-miterlimit: 10;
    stroke: #8b7df2;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke: #8b7df2;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}
