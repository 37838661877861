@import "../../App.scss";

.career-path-holder {
    width: 95%;
    h1 {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    h4 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    .career-cards {
        background-color: #fff;
        padding: 10px 20px 15px 20px;
        margin-top: 10px;
        box-shadow: 0px 3px 25px 0px #3c4dac1a;
    }
    .career-card {
        width: 100%;
        margin-left: -15px;
        position: relative;
        .slick-track {
            position: relative;
            display: flex !important;
            align-items: stretch;
            top: 0;
            left: 0;
        }
        .slick-list {
            overflow: hidden;
            position: relative;
            display: block;
            margin: 0;
            padding: 0;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
        .slick-slider {
            box-sizing: border-box;
            position: relative;
            display: block;
        }

        .slick-slide {
            margin-right: 10px;
            // width: 174px !important;
        }
        .slick-disabled {
            opacity: 0 !important;
        }
        .slick-next {
            right: 10px;
            top: 37%;
            width: 0px;
            height: 0px;
            z-index: 1;
            position: absolute;
            cursor: pointer;
            &:before {
                font-size: 0px;
                opacity: 0;
                display: none;
            }
        }
        .slick-prev {
            left: -25px;
            top: 37%;
            width: 0px;
            height: 0px;
            z-index: 1;
            position: absolute;
            cursor: pointer;
            &:before {
                opacity: 0;
                display: none;
                font-size: 0px;
            }
        }
        .card {
            font-family: Roboto;
            padding: 0px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            min-height: 165px;
            margin: 10px;
            margin-top: 0px;
            margin-right: -10px;
            border-radius: 8px;
            // width: 175px !important;
            box-shadow: 0px 3px 8px rgba(38, 38, 38, 0.2);
            .card-img-top {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
            .card-body {
                font-family: Roboto;
                font-style: normal;
                min-height: 70px;
                background-color: #fff;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                h5 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #101828;
                    // height: 35px;
                }
                p {
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #5855d6;
                }
                button {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: $button-color;
                    background: transparent;
                    border: none;
                }
            }
        }
    }
    .counselling-introduction {
        margin-top: 20px;
        background-color: #c0beff33;
        min-height: 244px;
        padding: 25px;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-wrap: wrap;
        position: relative;
        .introduction {
            width: 40%;
            h3 {
                //styleName: Sub headings;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #413c69;
            }
            p {
                padding: 7px 0px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #272727;
            }
            .counselling-btn {
                padding-top: 8px;
                button {
                    border: none;
                    color: #fff;
                    background-color: $button-color;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    height: 45px;
                    border-radius: 4px;
                    padding: 5px 20px;
                    &:nth-child(2) {
                        margin-left: 8px;
                        color: $button-color;
                        background-color: transparent;
                        border: 1px solid $button-color;
                    }
                }
            }
        }
        .counselling-img {
            width: 50%;
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
    .career-classes {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .career-class {
            width: 49%;
            box-shadow: 0px 3px 25px 0px #3c4dac1a;
            border: 1px solid #0000001a;
            min-height: 200px;
            padding: 20px;
            h4 {
                font-family: Roboto;
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #000000;
            }
            p {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
            }
            .outline-btn {
                padding: 9px 26px;
                background-color: transparent;
                border: 1px solid $button-color;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
            }
            &:nth-child(2) {
                margin-left: 18px;
            }
        }
    }

    .categories-holder {
        background-color: #fff;
        box-shadow: 0px 3px 25px 0px #3c4dac1a;
        // height: 214px;
        padding: 20px;
        h6 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 800;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        .category {
            border-radius: 8px;
            box-shadow: 0px 1px 6.599999904632568px 0px #5855d626;
            border: 1px solid #0000001a;
            width: 190px;
            height: 119px;
            position: relative;
            padding: 10px 15px;
            cursor: pointer;
            h3 {
                font-family: Roboto;
                font-size: 18px;
                font-weight: 600;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #272727;
            }
            &:nth-child(2) {
                margin-left: 10px;
            }
        }
    }

    .path-description {
        .path-desc {
            background-color: #fff;
            padding: 20px;
            box-shadow: 0px 3px 25px 0px #3c4dac1a;
            button {
                width: 126px;
                height: 40px;
                border: 1px solid $button-color;
                border-radius: 4px;
                background-color: transparent;
                color: $button-color;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: center;
                &:nth-child(2) {
                    margin-left: 20px;
                    background-color: $button-color;
                    color: #fff;
                }
            }
            p {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: justify;
                color: $heading;
            }
        }
        .path-chart {
            margin-top: 10px;
            padding: 20px;
            background-color: #fff;
            .tools {
                button {
                    background-color: #fff;
                    border: none;
                    border-radius: 50px;
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    img {
                        width: 20px;
                        vertical-align: middle;
                    }
                }
            }
            .pdf-fullscreen {
                overflow: auto;
                position: fixed;
                width: 100%;
                height: 100vh;
                left: 0px;
                top: 0px;
                bottom: 0px;
                backdrop-filter: blur(7px) brightness(10%);
                -webkit-backdrop-filter: blur(1px) brightness(10%);
                // filter: blur(7px) brightness(10%);
                background-color: #f7f6f6;
                z-index: 99;
                overflow-x: hidden;
                overflow-y: auto;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }

                .close-quiz {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    cursor: pointer;
                    z-index: 2;
                }

                .fullscreen-container {
                    position: relative;
                    height: 100vh;

                    #fullscreenimg {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }

            ul {
                padding-left: 20px;
                li {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #333269;
                }
            }
        }
    }

    .explore-path {
        background-color: #fff;
        height: 630px;
        text-align: center;
        padding: 30px;
        h2 {
            //styleName: Sub headings;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: center;
            color: #413c69;
            margin: 10px 0px;
        }
        p {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0em;
            text-align: center;
            color: #272727;
        }
        button {
            width: 200px;
            height: 40px;
            border: none;
            background-color: $button-color;
            color: #ffffff;
            border-radius: 4px;
            margin-top: 15px;
        }
    }
}

@media (max-width: 767px) {
    .career-path-holder {
        width: unset;
        padding: 10px;
        margin-bottom: 30px;
        .career-card {
            margin-left: 0px;
        }
        .counselling-introduction {
            padding: 15px;
            flex-wrap: nowrap;
            height: unset;
            min-height: 175px;
            .introduction {
                h3 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }
                .counselling-btn {
                    button {
                        padding: 5px 14px;
                    }
                }
            }
            .counselling-img {
                top: 27px;
                img {
                    width: 165px;
                }
            }
        }
        .career-classes {
            flex-wrap: wrap;
            .career-class {
                width: 100%;
                &:nth-child(2) {
                    margin-left: 0px;
                    margin-top: 10px;
                }
            }
        }
    }
}
