@import "../../App.scss";

.disclaimer-text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    margin-top: 20px;
}
.elite-rewards-holder {
    // margin-left: -50px;
    width: 90%;

    h1 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #222222;
    }
    p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #535259;
    }
    .elite-rewards-btns {
        margin-top: 12px;
        .active-btn {
            background: $dark-background;
            border: none;
            color: #fff;
            padding: 7px;
            width: 120px;
            font-weight: bold;
        }
        button {
            border: none;
            background: #f7f7fa;
            border: 1px solid #dddde2;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            padding: 7px;
            width: 120px;
            border-radius: 4px;
            transition: all 0.3s ease-in 0s;
        }
    }

    .elite-rewards-overview {
        margin-top: 20px;
        // width: 85%;
        .reward-coins {
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            // height: 266px;
            padding: 10px;
            margin-top: -10px;
            p {
                font-family: Lato;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 30px;
                color: #5fbb97;
            }
            small {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
            }
            .lifetime-coin {
                // width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                img {
                    width: 32px;
                    height: 32px;
                }
            }

            .redeem {
                border-radius: 4px;
                width: 120px;
                height: 32px;
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                background-color: $button-color;
                color: #fff;
                border: none;
                line-height: 16px;
            }
        }
        .earn-coins {
            margin-top: 10px;
            background-color: #fff;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            padding: 25px 30px;
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
                color: #000000;
            }
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 30px;
                color: #242326;
            }
            small {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .faq {
        padding: 10px;
        background: #ffffff;
        // min-height: 70vh;
        // width: 93%;
        margin-top: 10px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        h2 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            padding: 4px;
            padding-left: 2px;
        }
    }
    .redeem-header {
        display: flex;
        margin-top: 5px;
        .redeem-holder {
            // background: #ffffff;
            min-height: 40vh;
            width: 69%;
            padding-bottom: 20px;

            h2 {
                font-family: Roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }

            .reward-card {
                width: 100%;
                cursor: pointer;
                .background1 {
                    background-color: #f7f8ff;
                    margin-top: -5px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                .background2 {
                    background-color: #302c56;
                    margin-top: -5px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    .card-text {
                        color: #fff;
                    }
                    .redem-btn {
                        .redeem {
                            background-color: #302c56;
                            color: #fff;
                            border-color: #fff;
                            &:hover {
                                background-color: #8b7df2;
                                border-color: #8b7df2;
                            }
                        }
                    }
                }
                .card-body {
                    padding-bottom: 3px;
                    padding: 0.65rem;
                    h2 {
                        font-family: Lato;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 16px;
                        color: #e7c15f;
                    }
                }
                &:hover {
                    transition: all 0.4s ease-out;
                    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
                    top: -1px;
                    border: 1px solid #cccccc;
                    background-color: white;
                }
                &:hover:before {
                    transform: scale(2.15);
                }
                .card-text {
                    color: #000000;
                    text-align: center;
                    height: 40px;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        .history-desktop {
            // background: #ffffff;
            min-height: 70vh;
            width: 28%;
            margin-top: 25px;
            padding-top: 10px;
            padding-bottom: 20px;
            margin-left: 40px;
            #scrollableDiv1 {
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
            }
            .reward-history1 {
                background: #ffffff;
                min-height: 70vh;
                margin-top: -75px;
                position: sticky;
                position: -webkit-sticky;
                top: 150px;

                h1 {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #242326;
                }
                .reward-entry {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    padding: 15px;
                    align-items: center;
                    border-bottom: 1px solid #dee2e6;
                    h1 {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        color: #535259;
                    }
                    p {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 14px;
                        color: #82818c;
                    }
                    .credit {
                        font-family: Lato;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}

.modal-60w {
    width: 472px;
    .modal-body {
        text-align: center;
        padding: 1rem 0rem;
        h1 {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 32px;
            color: #51488c;
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 13px;
            cursor: pointer;
        }
        p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #535259;
        }
        .reward-image {
            height: 184px;
            background: #f5f5fa;
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .value {
                width: 104px;
                height: 32px;
                background-color: #51488c;
                border-radius: 16px;
                display: flex;
                align-items: center;
                padding: 0px 13px;
                font-family: "Lato";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
                color: #e7c15f;
                justify-content: center;
                white-space: nowrap;
                overflow: hidden;
            }
            small {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #b2b0bf;
            }
        }
        .offer-details {
            margin-top: 10px;
            h2 {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                color: #242326;
            }
            ol {
                list-style-type: decimal;
            }
            p {
                padding: 10px 60px;
                font-family: "Roboto";
                text-align: left;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #535259;
            }
        }
        input {
            border: 1px solid $chart-color;
            border-radius: 0px;
        }
        .redeem-btn {
            background-color: $button-color;
            border: none;
            color: white;
            border-radius: 4px;
            text-align: center;
            // width: 9%;
            height: 40px;
            width: 368px;
            font: normal normal 600 15px/27px Roboto;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            margin-left: -10px;
        }
        .version {
            padding: 12px 68px;
            .test-date-picker {
                display: flex;
                position: relative;
                width: 100%;
            }
            .details {
                font-family: "Roboto";
                text-align: left;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #535259;
            }
        }
    }
}

.modal-30w {
    width: 40%;
    .close-sticky-holder {
        position: relative;
        img {
            right: 0px;
            position: absolute;
            width: 18px;
            height: 18px;
            cursor: pointer;
        }
    }
    .greet {
        text-align: center;
        font: normal normal 700 22px/28px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        p {
            font-size: 19px;
        }
    }
}
.sm-modal {
    position: relative;
    .close-sticky-holder {
        top: 5px;
        right: 5px;
        z-index: 2;
        position: absolute;
        img {
            cursor: pointer;
            width: 15px;
            height: 15px;
        }
    }
}
@media (max-width: 767px) {
    .elite-rewards-holder {
        margin-left: 0px;
        width: 100%;
        margin-bottom: 20px;
        h1 {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            text-align: center;
        }
        .earn-coins {
            h1 {
                text-align: left;
                margin-left: 5px;
            }
        }
        .elite-rewards-overview {
            width: unset;
            margin-left: -20px;
            .reward-coins {
                padding: 20px 25px;
                margin-top: 0px;
            }
            .earn-coins {
                padding: 25px 20px;
                small {
                    font-size: 16px;
                }
            }
        }
        .elite-rewards-btns {
            display: flex;
            overflow: auto;
            margin-left: -10px;
            margin-right: 10px;
            // overflow:
            &::-webkit-scrollbar {
                display: none;
            }
            button {
                padding: 8px 25px;
                width: unset;
            }
        }
        .redeem-header {
            .redeem-holder {
                margin-top: 10px;
                width: 100%;
                padding-bottom: 40px;
                h2 {
                    padding-left: 30px;
                }
                .card {
                    width: 18rem !important;
                    margin: 0 auto;
                    float: none;
                    margin-bottom: 10px;
                    .card-img-top {
                        height: 130px;
                        width: auto;
                    }
                    .skill-cost {
                        top: 87%;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                        p {
                            font-size: 14px;
                            margin-top: -4px;
                        }
                    }
                    .cost {
                        top: 114px;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                        p {
                            font-size: 14px;
                            margin-top: -4px;
                        }
                    }
                    .redem-btn .redeem {
                        width: 100%;
                        padding: 10px;
                        font: normal normal 600 15px/18px Roboto;
                    }
                    .card-text {
                        font: normal normal 600 15px/18px Roboto;
                        color: #000000;
                        padding-left: 45px;
                        padding-right: 45px;
                        padding-bottom: 40px;
                    }

                    .mobile-card {
                        display: flex;
                        justify-content: center;
                        border-radius: 5px;
                    }
                }
            }
        }
        .redeem-header .redeem-holder .card {
            width: 18rem !important;
            float: none;
            margin: 0 20px 10px;
            .card-body {
                h2 {
                    margin-left: -30px;
                }
            }
        }

        .reward-history {
            margin-top: 10px;
            width: 100%;
        }
        .reward-history {
            background: #ffffff;
            min-height: 70vh;
            width: unset;
            margin-top: 15px;
            margin-left: -20px;
            .reward-entry {
                width: 100%;
                display: flex;
                justify-content: space-between;
                padding: 20px;
                align-items: center;
                border-bottom: 1px solid #dee2e6;
                h1 {
                    color: #535259;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: left;
                }
                p {
                    color: #82818c;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                }
                .credit {
                    font: normal normal bold 20px/18px Roboto;
                }
            }
        }
        .faq {
            width: unset;
            margin-left: -20px;
            h2 {
                font-family: Roboto;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }
            p {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                padding: 4px;
                padding-left: 2px;
            }
        }
        .empty-screen {
            margin-bottom: 350px;
        }
    }
    .modal-30w {
        width: unset;
        .greet {
            font: normal normal 700 22px/35px Roboto;
            p {
                font: normal normal 700 18px/22px Roboto;
            }
        }
    }
    .modal-60w {
        width: unset;
        .modal-body {
            .redeem-btn {
                width: 255px;
                margin-left: -9px;
            }
        }
        ol {
            list-style-type: decimal;
        }

        .sticky-close {
            position: absolute;
            right: 13px;
            top: 13px;
            cursor: pointer;
        }
    }
}

@media (max-width: 768px) {
    .history-desktop {
        display: none;
    }
}
@media only screen and (max-width: 1300px) {
    .redeem-header .redeem-holder .reward-card .redem-btn .redeem {
        width: 100%;
        padding: 10px;
    }
    .reward-holder .rewards-card .rewards-card .redem-btn .redeem {
        width: 100%;
    }
}
@media only screen and (min-width: 1400px) {
    #scrollableDiv1 {
        height: 900px !important;
    }
}
@media only screen and (min-width: 414px) and (max-width: 736px) {
    .reward-holder .rewards-fluid .rewards-card1 .cost {
        top: 84%;
    }
}
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
    .redeem-header .redeem-holder .reward-card {
        .cost {
            top: 87%;
        }
        .skill-cost {
            top: 85%;
        }
    }

    #scrollableDiv1 {
        height: 750px !important;
    }
}

@media only screen and (min-width: 1601px) and (max-width: 1920px) {
    .redeem-header .redeem-holder .reward-card {
        .cost {
            top: 89%;
        }
    }
}
@media only screen and (min-width: 1920px) {
    .redeem-header .redeem-holder .reward-card {
        .cost {
            top: 89%;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .elite-rewards-holder {
        margin-left: 0px;
        width: 98%;
        .faq {
            img {
                width: 280px !important;
            }
        }
    }
    .history-desktop {
        display: block;
    }
    .rewards-holder .rewards-header {
        top: 68px !important;
    }
    .reward-holder .rewards-card .reward-card {
        width: 100% !important;
    }
}

#mob::-webkit-outer-spin-button,
#mob::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
}
#mob::-webkit-input-placeholder {
    font-size: 12px;
}
/* Firefox */
#mob {
    -moz-appearance: textfield;
}
#mob::-moz-placeholder {
    font-size: 12px;
}
#mob:-ms-input-placeholder {
    font-size: 12px;
}

//Contest page
.contest-main {
    // background: #f6f6f6;
    @media (max-width: 768px) {
        min-width: 600px;
    }
}
.join-contest-text {
    color: #1f2d3d;
    font-family: Roboto;
    font-size: 24px;
    text-align: left;
    margin: 0;
    padding: 15px 0;
}
.join-contest-text-span {
    font-family: Roboto;
    font-weight: bold;
    line-height: 32px;
}
.join-contest-text-para {
    color: #3b3f44;
    font-size: 16px;
    line-height: 1.5;
    text-align: left;
    font-family: Roboto;
    margin: 0;
    text-align: left;
}
.join-contest-text2 {
    color: #1f2d3d;
    font-size: 18px;
    font-family: Roboto;
    text-align: left;
    font-weight: bold;
    margin: 0;
}
.contest-btn {
    width: 285px;
    border-radius: 4px;
    color: #ffffff;
    font-family:
        Open Sans,
        Arial;
    font-size: 16px;
    font-style: normal;
    line-height: 1.5;
    // padding: 12px 0;
    text-align: center;
    border: none;
}
