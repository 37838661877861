body {
  margin: 0;
  scrollbar-width: thin;
  background-color: #f9f9ff;
  line-height: normal;
  font-family: Roboto;
  &::-webkit-scrollbar {
    display: none;
  }
  // & ::-webkit-scrollbar-track {
  //   border-radius: 10px;
  //   background-color: white;
  // }
  // &::-webkit-scrollbar {
  //   width: 6px;
  //   background-color: #ffffff;
  // }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    max-height: 10px;
    background-color: #e9e8e8;
  }
}

html,
body {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
