@import "../../App.scss";

.input-edit {
    border: none;
    background-color: #ffffff !important;
    &:focus {
        border: none;
        box-shadow: none;
    }
    resize: none !important;
}
.notes-holder {
    margin-left: 5px;
    .no-notes {
        background-color: #fff;
        padding: 40px;
        text-align: center;
        width: 98%;
    }
    .notes-top-holder {
        padding: 25px;
        background-color: #fff;
        width: 97%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
            font-family: Roboto;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0px;
            text-align: left;
            color: #222222;
        }
        .has-search {
            flex: 0.5;
            position: relative;
            .form-control-feedback {
                position: absolute;
                z-index: 5;
                display: block;
                width: 2.375rem;
                height: 2.375rem;
                line-height: 2.375rem;
                text-align: center;
                pointer-events: none;
                color: #aaa;
                margin-top: 3px;
                width: 24px;
                margin-left: 5px;
            }

            .form-control {
                padding-left: 1.9rem;
            }
            #search {
                &::-webkit-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::-moz-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &:-ms-input-placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
                &::placeholder {
                    color: #9b9aa3;
                    font-size: 13px;
                }
            }
        }

        .dropdown {
            display: inline-block;
            margin-right: 15px;
            #dropdown-basic-button {
                background: #fff;
                border: none;
                color: #696873;
                box-shadow: none;
                &::after {
                    display: inline-block;
                    margin-left: 0.605em;
                    vertical-align: 0.15em;
                    content: "";
                    color: #9b9aa3;
                    border-top: 0.4em solid;
                    border-right: 0.4em solid transparent;
                    border-bottom: -4.7em solid transparent;
                    border-left: 0.4em solid transparent;
                }
                &:focus {
                    box-shadow: none;
                }
            }
            .dropdown-menu.show {
                display: block;
                padding: 24px 5px 10px 20px;
                // transform: translate(14px, 54px) !important;
                border: none;
                box-shadow: 0px 0px 3px 3px #0000001f;
                z-index: 4 !important;
                // width: 300px;
                .subscribe {
                    list-style: none;
                    li {
                        cursor: pointer;
                        margin-bottom: 14px;
                        min-width: 210px;
                        justify-content: stretch;
                        align-items: center;
                        .checkbox-square {
                            width: 18px;
                            height: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 0.5px solid #606060;
                            border-radius: 3px;
                            margin-right: 10px;
                            float: left;
                        }
                        h4 {
                            float: left;
                        }
                    }
                }
                .type {
                    list-style: none;
                    li {
                        // display: flex;
                        cursor: pointer;
                        margin-bottom: 14px;
                        min-width: 210px;
                        // width: 20px;
                        .checkbox-square {
                            width: 18px;
                            height: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 0.5px solid #606060;
                            border-radius: 3px;
                            margin-right: 10px;
                            float: left;
                        }
                        h4 {
                            float: left;
                        }
                    }
                }
                .activity {
                    list-style: none;
                    li {
                        // display: flex;
                        cursor: pointer;
                        margin-bottom: 14px;
                        .checkbox-square {
                            width: 18px;
                            height: 18px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 0.5px solid #606060;
                            border-radius: 3px;
                            margin-right: 10px;
                            float: left;
                        }
                        h4 {
                            float: left;
                        }
                    }
                }
            }
        }
    }
    .notes-list {
        padding-top: 15px;
        width: 99%;
        ul li {
            width: 33%;
            display: inline-block;
            margin-bottom: 10px;
            vertical-align: middle;
            cursor: pointer;
            .carusole-card {
                margin-right: 12px;
                box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.14);
                transition: all 0.5s ease;
                border-radius: 5px;
                position: relative;
                background-color: #fff;
                padding: 10px;
                height: 210px;
                h4 {
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #484750;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-height: 105px;
                }
                p {
                    color: #9b9aa3;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                }
                .notes-description {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    height: 90px;
                    //styleName: Body Text;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: #696873;
                }
                small {
                    //styleName: Caption;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $button-color;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 30px;
                }
                img {
                    border-radius: 4px;
                }
            }
        }
    }
}
.clear-filter {
    background-color: #fff;
    width: 97%;
    .clear {
        display: flex;
        padding-left: 25px;
        p {
            color: $button-color;
            cursor: pointer;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: bold;
            line-height: 18px;
            letter-spacing: 0px;
            text-align: left;
        }
        .select-list {
            width: 86%;
            ul {
                margin-left: 15px;
                display: flex;
                flex-wrap: wrap;
                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #9b9aa3;
                    font-family: Roboto;
                    font-size: 12px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    padding: 5px 7px;
                    background-color: #ffffff;
                    margin-right: 5px;
                    border-radius: 50px;
                    margin-bottom: 10px;
                    margin-top: -2px;
                    img {
                        width: 8px;
                        cursor: pointer;
                        vertical-align: middle;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
.pagination-holder {
    padding: 10px;
    // margin-left: -68px;
    ul {
        li {
            margin: 0 5px;
            &.active {
                a {
                    background-color: $button-color;
                    color: #fff;
                }
            }
            a {
                color: #52575d;
                font-family: Roboto;
                background-color: #fff;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                padding: 5px 12px;
                border-radius: 4px;
            }
        }
    }
}
@media (max-width: 767px) {
    .quiz-holder {
        .ques-title {
            line-height: 24px !important;
        }
        ul {
            width: 100% !important;
        }
    }
    .notes-holder {
        margin-left: 0px;
        margin-bottom: 20px;

        .notes-top-holder {
            // flex-wrap: wrap;
            display: inline-block;
            justify-content: unset;
            margin-left: -13px;
            margin-top: -25px;
            width: 104%;
            position: sticky;
            position: -webkit-sticky;
            top: 63px;
            z-index: 2;
            .has-search {
                margin: 15px 0px;
            }
            .dropdown {
                margin-right: 15px;
                .dropdown-menu.show {
                    // transform: translate(-50px, 54px) !important;
                    padding: 20px 2px 9px 15px;
                }
            }
        }
        .clear-filter {
            display: inline-block;
            justify-content: unset;
            margin-left: -13px;
            margin-top: -23px;
            width: 104%;
            position: sticky;
            position: -webkit-sticky;
            top: 249px;
            .clear {
                padding-left: 15px;
                .select-list {
                    width: unset;
                }
            }
        }
        .notes-list {
            padding-top: 15px;
            width: 99%;
            ul li {
                width: 100%;
                .carusole-card {
                    margin-left: -5px;
                    margin-right: 5px;
                }
            }
        }
    }
}

/// for Notes popup
.feeds-details {
    margin-right: 0;
    margin-top: 0 !important;
    margin-bottom: 0;
    width: calc(100vw - 291px);
    min-height: calc(100% - 0rem);
    height: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9;
    // background: #fff;
    &::before {
        background: rgba(51, 51, 51, 0.68);
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        content: "";
        width: calc(80vw - 0px);
    }

    .modal-content {
        height: 100%;
        border: 0;
    }

    .modal-body {
        padding: 0;
        background: #f6f6f6;
        height: 100%;

        .notes-details {
            width: 100%;
            background: #fff;
            height: 100%;
            padding: 25px 50px;
            overflow: auto;
            scrollbar-width: thin;
            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }

            .react-pdf__Page__textContent {
                display: none;
            }
            .content-middle {
                background: #fff;
                border: 1px solid #ccc;
                border-radius: 6px;
                min-height: calc(100vh - 256px);
                margin-top: 15px;
                overflow-y: auto;
                padding: 15px;
                position: relative;
                width: 85%;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
                .tools {
                    position: absolute;
                    top: 10px;
                    button {
                        border: none;
                        background-color: transparent;
                        width: 40px;
                        height: 40px;
                        margin-right: 10px;
                        img {
                            width: 20px;
                        }
                        &:hover {
                            background-color: #f6f6f6;
                            border-radius: 50%;
                        }
                    }
                }
                .react-pdf__Document {
                    position: relative;
                    .zoom-btn {
                        position: absolute;
                        // right: 10px;
                        top: -10px;
                        z-index: 1;
                        button {
                            border: none;
                            background-color: transparent;
                            width: 45px;
                            height: 45px;
                            img {
                                width: 20px;
                            }
                            &:hover {
                                background-color: #f6f6f6;
                                border-radius: 50%;
                            }
                        }
                    }
                    .react-pdf__Page {
                        span {
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -o-user-select: none;
                            user-select: none;
                        }
                    }
                    .react-pdf__Page__annotations {
                        display: none;
                    }
                }
                .article-view {
                    button {
                        border: none;
                        background-color: $button-color;
                        color: #fff;
                        border-radius: 4px;
                        padding: 12px 40px;
                    }
                }
            }
            .playlist-header {
                position: relative;
                h1 {
                    small {
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 22px;
                        font-family: Roboto;
                    }
                }
                p {
                    color: gray;
                    font-size: 13px;
                }
                .sticky-cross-holder {
                    position: absolute;
                    right: -20px;
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.5;
                    vertical-align: middle;
                    top: 2px;
                    cursor: pointer;
                    img {
                        width: 18px;
                    }
                }
            }
            .quiz-holder {
                margin-top: 100px;
                display: flex;
                flex-direction: column;
                // justify-content: center;
                align-items: center;
                h1 {
                    font-size: 20px;
                }
                .ques-title {
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 14px;
                    letter-spacing: 0px;
                    text-align: center;
                    color: #555555;
                    p {
                        min-height: 30px;
                        span {
                            line-height: 24px;
                        }
                    }
                }

                ul {
                    list-style: none;
                    margin-top: 10px;
                    width: 80%;
                    li {
                        // display: flex;
                        justify-content: center;
                        align-items: center;
                        .option {
                            border: 1px solid #ddd;
                            width: 70%;
                            min-height: 35px;
                            margin-top: 10px;
                            box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                            padding: 5px;
                            margin-left: 17px;
                            cursor: pointer;
                            margin-right: -10px;
                            color: #555555;
                            p {
                                color: #555555;
                                span {
                                    color: #555555;
                                }
                                img {
                                    vertical-align: middle;
                                }
                            }
                        }

                        .option1 {
                            border: 1px solid #ddd;
                            width: 70%;
                            min-height: 35px;
                            margin-top: 10px;
                            box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                            padding: 5px;
                            margin-left: 29px;
                            cursor: pointer;
                            color: #555555;
                            p {
                                color: #555555;
                                span {
                                    color: #555555;
                                }
                                img {
                                    vertical-align: middle;
                                    &:hover {
                                        -ms-transform: scale(4); /* IE 9 */
                                        -webkit-transform: scale(4); /* Safari 3-8 */
                                        transform: scale(4);
                                        position: relative;
                                        /* right: -380px; */
                                        z-index: 9999;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .description {
                margin-left: -27px;
                background: #fff;
                padding: 15px;
                border-radius: 6px;
                // height: calc(100vh - 275px);
                .row {
                    justify-content: flex-start;
                    align-items: flex-start;
                }
                .articlediv {
                    min-height: 160px;
                    overflow: auto;
                    .content-middle {
                        border-radius: 6px;
                        height: calc(100vh - 50px);
                        width: 100%;
                        overflow-y: auto;
                        position: relative;
                        margin-top: 15px;
                    }
                }
                .embed-responsive-item {
                    width: 80%;
                    height: 320px;
                }
            }
            .like-btn {
                display: flex;
                list-style: none;
                margin-top: 30px;
                .liked-active {
                    color: $chart-color !important;
                }
                li {
                    float: none;
                    border: 1px solid #eee;
                    padding: 5px 7px;
                    border-radius: 33px;
                    margin-left: 5px;
                    margin-right: 8px;
                    font-size: 14px;
                    cursor: pointer;
                    font-weight: 500;
                    img {
                        vertical-align: middle;
                    }
                }
            }

            .save-notes {
                width: 50%;
                textarea {
                    resize: both;
                }
                .addWidth {
                    // margin-top: -45px;
                    margin-left: -2px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .removeWidth {
                    margin-top: 0px;
                    margin-left: 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                p {
                    //styleName: Action Text;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 18px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $button-color;
                }
                span {
                    //styleName: Body Text;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0px;
                    color: #9b9aa3;
                }
            }
            .videodiv {
                .sticky-cross-holder {
                    position: absolute;
                    right: -20px;
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.5;
                    vertical-align: middle;
                    top: 1px;
                    cursor: pointer;
                    img {
                        width: 18px;
                    }
                }
                .content-details {
                    position: relative;
                }

                .content-middle1 {
                    background: #fff;
                    padding: 0px;
                    border-radius: 6px;
                    height: calc(100vh - 100px);
                    // overflow-y: auto;
                    .elipsis {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .question {
                        padding-left: 23px;
                        text-align: left;
                    }
                    h6 {
                        font-size: 1.1rem;
                        margin-bottom: 1rem;
                        font-weight: 400;
                        color: #d4d4d4;
                    }

                    p {
                        margin-bottom: 20px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
@media (max-width: 767px) {
    .feeds-details {
        top: 67px;
        max-width: 100%;
        width: 100%;
        z-index: 5;
        margin-top: 0;
        padding-bottom: 0;
        .modal-body {
            .padd {
                padding: 15px 30px !important;
            }
            .notes-details {
                padding: 15px;
                overflow-x: hidden;
                .playlist-header {
                    .topic-back {
                        width: 80%;
                    }
                    .sticky-cross-holder {
                        width: 35px;
                        top: -5px !important;
                        right: -12px !important;
                        height: 35px;
                        background: #fff;
                        border: 1px solid #f5821f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 6px;
                        img {
                            width: 14px;
                        }
                    }
                }
                .description {
                    padding-right: 0px;
                    padding-left: 12px;
                    margin-right: -15px;
                    .embed-responsive-item {
                        width: 100%;
                        height: 315px;
                    }
                    .activeFeed-image {
                        margin-top: 20px;
                        display: flex;
                        justify-self: center;
                        padding-right: 0px;
                        img {
                            width: 96% !important;
                        }
                    }
                }
                .save-notes {
                    width: 100%;
                    margin-bottom: 125px;
                }
                .videodiv {
                    .sticky-cross-holder {
                        width: 35px;
                        height: 35px;
                        background: #fff;
                        border: 1px solid #f5821f;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 6px;
                        img {
                            width: 14px;
                        }
                        z-index: 2;
                        top: -9px;
                        right: -10px;
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .videodiv .content-middle1 {
        width: 55%;
        margin: 0 auto;
    }
}
.cursor-pointer {
    cursor: pointer;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .feeds-details {
        max-width: 100%;
        width: 100%;

        .header-div {
            padding: 10px 15px !important;

            .left {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .right-icon {
                button {
                    padding: 0.375rem 0.5rem;
                }
            }
        }
    }
}
